import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import Startups from "./pages/Startups";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Links from "./pages/Links";
import Footer from "./components/Footer";
import Collaborations from "./pages/Collaborations";
import Events from "./pages/Events";
import VentureStudio from "./pages/VentureStudio";

const pages = [
    { endpoint: "/", title: "Home", component: <Home />, hidden_from_nav: false },
    { endpoint: "/startups", title: "Startups", component: <Startups />, hidden_from_nav: false },
    { endpoint: "/venture-studio", title: "Venture Studio", component: <VentureStudio />, hidden_from_nav: false },
    { endpoint: "/about", title: "About us", component: <AboutUs />, hidden_from_nav: false },
    { endpoint: "/events", title: "Events", component: <Events />, hidden_from_nav: true },
    { endpoint: "/collaborations", title: "Collaborations", component: <Collaborations />, hidden_from_nav: true },
    { endpoint: "/contact", title: "Contact", component: <ContactUs />, hidden_from_nav: false },
    { endpoint: "/links", title: "Links", component: <Links />, hidden_from_nav: true },
];

const LoadPageContent = () => {
    const location = useLocation(); // Using useLocation hook
    useEffect(() => {
        const sections = document.querySelectorAll("section");

        const fadeInOptions = {
            threshold: 0.2,
        };

        const fadeInObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("fade-in");
                    entry.target.classList.remove("fade-out"); // Remove fade-out class
                    observer.unobserve(entry.target);
                }
            });
        }, fadeInOptions);

        sections.forEach((section) => {
            fadeInObserver.observe(section);
        });
    }, [location]);
};

const ScrollToTop = () => {
    const { pathname } = useLocation();
    const hash = window.location.hash;

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1)); // Remove the '#' from hash
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [pathname, hash]); // Add hash to the dependency array

    return null;
};

function App() {
    // eslint-disable-next-line
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <Router>
            <ScrollToTop />
            <NavBar pages={pages} />
            <LoadPageContent />
            <Routes>
                {pages.map((each, idx) => (
                    <Route path={each.endpoint} element={each.component} key={idx} />
                ))}
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
