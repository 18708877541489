import React from "react";

const Links = (props) => {
    const elements = [
        {
            title: "LinkedIn",
            href: "https://linkedin.com/company/hard-stuff",
            icon: "https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Bug.svg.original.svg",
        },
        {
            title: "Eventbrite",
            href: "https://www.eventbrite.co.uk/o/hard-stuff-80412554053",
            icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_BKN4bCATJNKMZ8COh6oR3jFJc465k-XVSw&s",
        },
        {
            title: "MailChimp",
            href: "https://hard-stuff.us22.list-manage.com/subscribe?u=4cda4d008dbb6aedf51b6ebd1&id=d582a17977",
            icon: "https://www.svgrepo.com/show/330880/mailchimp.svg",
        },
        {
            title: "GitHub (open source)",
            href: "https://github.com/orgs/Hard-Stuff/repositories",
            icon: "https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg",
        },
    ];

    return (
        <>
            <h1 className="hardstuff-no-colour">Find us here:</h1>
            <div className="content">
                {elements.map((el) => (
                    <a href={el?.href}>
                        <button style={{ minWidth: "50%" }}>
                            {el?.icon && <img src={el?.icon} height={48}></img>}
                            <span style={{ padding: "12px" }}>{el?.title}</span>
                        </button>
                    </a>
                ))}
            </div>
            <div className="content">
                Or you can
                <a href="mailto:hello@hard-stuff.com">
                    <button style={{ fontWeight: 400 }}>send us an email</button>
                </a>
            </div>
        </>
    );
};

export default Links;
