// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-module__layout___ZT1i5 {\n  display: grid;\n}\n", "",{"version":3,"sources":["webpack://./src/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf","sourcesContent":[".layout {\n  display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "index-module__layout___ZT1i5"
};
export default ___CSS_LOADER_EXPORT___;
