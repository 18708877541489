import "../App.css";
import FlexBox from "../components/FlexBox";
import MaskedImg from "../components/MaskedImg";

// pictures
import nick from "../assets/images/nick_speaking (2).png";
import matt from "../assets/images/matt_speaking.png";

// icons
import linkedin_icon from "../assets/icons/linkedin.png";

const AboutUs = (props) => {
    const first_elems = [
        {
            title: "🌱 Our Mission",
            content: (
                <div>
                    <p>
                        <span className="hardstuff-no-colour">Our mission</span> is to empower entrepreneurs and
                        startups to solve tough challenges.
                        <br />
                        Challenges like getting enough food, heat, good health, and feeling safe.
                    </p>
                    <p>
                        These challenges, and most of the other <strong>really</strong> tough ones, are all in the
                        physical space, so we only work on physical solutions <em>i.e. "Hardware".</em>
                    </p>
                    <p>
                        We do this by providing startups with the proof, hardware, support, resources, and partnerships
                        they need to bring their groundbreaking ideas to life.
                    </p>
                </div>
            ),
        },
        {
            title: "🌍 Our Vision",
            content: (
                <div>
                    <p>
                        <span className="hardstuff-no-colour">We envision a world</span> with a stable and safe climate,
                        without plastic in our oceans, with nature thriving, without hunger, and where everyone
                        everywhere is safe.
                    </p>
                    <p>
                        We're a looong way off.
                        <br />
                        <br />
                        Which means that this stuff, this really really "hard stuff", requires hard work and hardware
                        innovation to change the game.
                    </p>
                </div>
            ),
        },
        {
            title: "🚀 Built differently",
            content: (
                <div>
                    <p>
                        We work with hardware startups in a very close way, bringing to life a solution that solves the
                        problem in the right way at the right place.
                        <br />
                        <br />
                        We have hands-on experience with high-impact industries including agriculture, EVs, mobility,
                        public heath, citizen science, marine, energy-storage, IoT, and climate impact.
                    </p>
                </div>
            ),
        },
    ];
    const second_elems = [
        {
            title: (
                <span>
                    🔨 we <span className="hardstuff-no-colour">make stuff</span>
                </span>
            ),
            content: (
                <p>
                    We're hardware engineers with a drive to make stuff that matters.
                    <br />
                    <br />
                    We build the prototypes, proof of concepts, and minimum viable products that impactful startups need
                    to demonstrate to customers, investors, and stakeholders what their vision can really be!
                </p>
            ),
        },
        {
            title: (
                <span>
                    🧯 we <span className="hardstuff-no-colour">test stuff</span>
                </span>
            ),
            content: (
                <p className="pillar-content">
                    Fail while it's cheap!
                    <br />
                    <br />
                    The sooner you test <em>something</em> the sooner you can validate your assumptions, and start
                    making a real impact.
                    <br />
                    We love getting our travel toolkits out, our muddy boots on, and getting knee-deep in the muck
                    alongside you to really prove your idea makes sense.
                </p>
            ),
        },
        {
            title: (
                <span>
                    ⚡ we <span className="hardstuff-no-colour">teach stuff</span>
                </span>
            ),
            content: (
                <p className="pillar-content">
                    "Being a founder is a piece of 🍰".{" "}
                    <sub>
                        <em>(said no one, ever)</em>
                    </sub>
                    <br />
                    <br />
                    We turn engineers into CTOs, working alongside startups, incubators and accelerators to get hardware
                    startups achieving their maximum impact potential. <br />
                    <br />
                    We do this through technical and non-technical workshops, and in teaching why now (of all times) is
                    the time to make <span className="hardstuff-no-colour">Hard Stuff</span>!
                </p>
            ),
        },
    ];
    return (
        <>
            <h1>About Us</h1>
            <sub>
                <em>...because CO2 is a physical thing, we need physical solutions...</em>
            </sub>
            <div className="content">
                <FlexBox defaultValue={0} elements={first_elems} cycle={true} />
                <section>
                    <p>
                        It should be <strong>easier</strong> to launch startups that are solving the really tough
                        problems, not harder.
                    </p>
                    <p>
                        Yet 5x more investment goes in to software than hardware (let alone eco and enviro-tech), and is
                        it just us or is all this AI and SaaS just getting dull?
                    </p>
                    <p>
                        So <span className="hardstuff-no-colour">Hard Stuff</span> is here to do more with less, and
                        prove that <strong>now is the time to build impactful hardware</strong>.
                    </p>
                </section>
                <section>
                    <FlexBox defaultValue={2} elements={second_elems} cycle={true} />
                </section>
                <section>
                    <h2 style={{ textAlign: "left" }}>Our founders</h2>
                    <div className="stack_responsive">
                        <div style={{ textAlign: "center" }}>
                            <h3>
                                <a href="https://www.linkedin.com/in/m-a-stedman/">
                                    <img src={linkedin_icon} width="40px" alt="linkedin icon" />
                                </a>{" "}
                                Matt Stedman
                            </h3>
                            <h4>Chief of Engineering</h4>
                            <p>
                                Matt's been a professional prototyper and venture builder for the past 6 years, working
                                with some of the biggest and most impactful technology startups.
                            </p>
                            <MaskedImg src={matt} style={{ maxWidth: "60%" }} />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <h3>
                                <a href="https://www.linkedin.com/in/nickbond/">
                                    <img src={linkedin_icon} width="40px" alt="linkedin icon" />
                                </a>{" "}
                                Nick Bond
                            </h3>
                            <h4>Chief of Operations</h4>
                            <p>
                                Nick's got over 10 years in finance, operations, compliance, and project management, in
                                FinTech - now using his powers for good!
                            </p>
                            <MaskedImg src={nick} style={{ maxWidth: "60%" }} />
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default AboutUs;
