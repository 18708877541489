import React, { useEffect, useRef, useState } from "react";
import what_hard_stuff_does from "./FitInFigure_assets/What Hard Stuff does.svg";
import what_you_do from "./FitInFigure_assets/What you do.svg";
import arrows from "./FitInFigure_assets/Arrows.svg";

const images = [what_hard_stuff_does, what_you_do, arrows];
const aspect_ratio = 0.59167;

const FitInFigure = ({ height, maxWidth, style }) => {
	const containerRef = useRef(null);
	const [imagePositions, setImagePositions] = useState({
		imgPos: 0,
		imgOpacity: 0,
		arrowOpacity: 0,
	});

	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current) {
				const containerTop = containerRef.current.getBoundingClientRect().top;
				const viewportHeight = window.innerHeight;
				const scrollY = window.scrollY;
				const containerHeight = containerRef.current.clientHeight;

				// Calculate position based on scroll
				let imgPosPx = Math.max(0, containerTop + (containerHeight * 2) - scrollY);
				let imgPosPercent = (imgPosPx / containerHeight) * 100;
				let imgOpacity = Math.max(100 * (1 + Math.min(0, (containerTop - 2 * imgPosPx) / viewportHeight)), 0);

				let arrowOpacity = Math.min(
					Math.max(
						((viewportHeight * 0.75 - (containerTop + containerHeight / 2)) / (viewportHeight * 0.2)) * 100,
						0
					),
					100
				);

				setImagePositions({
					imgPos: imgPosPercent,
					imgOpacity: imgOpacity,
					arrowOpacity: arrowOpacity,
				});
			}
		};

		const onScroll = () => {
			requestAnimationFrame(handleScroll);
		};

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	return (
		<div
			ref={containerRef}
			className="image-stack-container"
			style={{
				position: "relative",
				height: height || `calc(${maxWidth} / ${aspect_ratio})`,
				width: maxWidth || `calc(${height} * ${aspect_ratio})`,
                maxWidth: maxWidth || "100%",
				...style,
			}}
		>
			<div
				style={{
					...imageContainerStyle,
					opacity: `${imagePositions.imgOpacity}%`,
					transform: `translateY(${imagePositions.imgPos}%)`,
					transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
				}}
			>
				<img src={images[1]} alt="2" style={imgStyle} />
			</div>
			<div
				style={{
					...imageContainerStyle,
					opacity: `${imagePositions.imgOpacity}%`,
					transform: `translateY(${-imagePositions.imgPos}%)`,
					transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
				}}
			>
				<img src={images[0]} alt="1" style={imgStyle} />
			</div>
			<div
				style={{
					...imageContainerStyle,
					opacity: `${imagePositions.arrowOpacity}%`,
					transition: "opacity 0.3s ease-out",
				}}
			>
				<img src={images[2]} alt="3" style={imgStyle} />
			</div>
		</div>
	);
};

const imgStyle = {
	height: "100%",
	width: "100%",
	objectFit: "contain",
};

const imageContainerStyle = {
    position: "absolute",
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
    pointerEvents: "none"
};

export default FitInFigure;
