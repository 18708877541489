import "./NavBarHeaderFooter.css";

import linkedin_icon from "../assets/icons/linkedin.png";
// import email_icon from "../assets/icons/email.png";

const Footer = () => {
    return (
        <>
            <footer className="lock-footer" />
            <footer className="footer">
                <div className="content">
                    Find us on{" "}
                    <a href="https://www.linkedin.com/company/hard-stuff">
                        LinkedIn <img src={linkedin_icon} width="16px" alt="linkedin icon" />
                    </a>
                    <br />
                    <a href="/contact">Contact us here</a>
                    <br />
                    <a href="/links">Other socials links</a>
                    <br />
                </div>
                <div className="content">
                    &copy; {new Date().getFullYear()} <span className="hardstuff">Hard Stuff</span>. All rights
                    reserved.
                    <br />
                    <p style={{ fontSize: "small" }}>
                        Hard Stuff is the trading name of <em>Hardware is Easy Ltd. #15281302</em>
                        <br />
                        We're based in London, UK.
                    </p>
                </div>
            </footer>
        </>
    );
};

export default Footer;
