import "../App.css";

import { WhatIsHardStuffDoing } from "./Home-sections/WhatIsHardStuffDoing";
import BuildTestXRepeat from "../components/BuildTestXRepeat";
import ItsTimeToBuild from "../components/ItsTimeToBuildX";

// Pictures
import smartshift_prototype from "../assets/images/smartshift_prototype.png";
import events from "../assets/images/events.jpg";
import engineering from "../assets/images/engineering.png";
import waterqm_prototype from "../assets/images/waterqm_prototype (3).png";
import FlexBox from "../components/FlexBox";
import what_we_do from "../assets/images/what_we_do.png";

const Home = (props) => (
    <div className="App">
        <section>
            <h1 className="big-h1">
                <span className="hardstuff">Hard Stuff </span>
            </h1>
            <BuildTestXRepeat />
        </section>
        <section>
            <div className="content" style={{ maxWidth: "90%" }}>
                <h2>
                    <span className="hardstuff">Hard Stuff</span> launches climate hardware.
                </h2>
                <FlexBox
                    defaultValue={0}
                    elements={[
                        {
                            title: (
                                <span>
                                    🔨 Prototyping & field-testing for climate hardware startups at TRL 0 to 5
                                    <br />
                                    <br />
                                    <a href="/startups">/startups</a>
                                </span>
                            ),
                            img: smartshift_prototype,
                        },
                        {
                            title: (
                                <span>
                                    💡 Fractional CTO for startups, skunkworks, and spinouts at TRL 0 to 9
                                    <br />
                                    <br />
                                    <a href="/startups#fractional-cto">/fractional-cto</a>
                                </span>
                            ),
                            img: engineering,
                        },
                        {
                            title: (
                                <span>
                                    🌱 Building new meaningful ventures in under tapped enviro-tech verticals
                                    <br />
                                    <br />
                                    <a href="/venture-studio">/venture-studio</a>
                                </span>
                            ),
                            img: waterqm_prototype,
                        },
                        {
                            title: (
                                <span>
                                    🚀 Inspiring gamechangers through incubator workshops and events
                                    <br />
                                    <br />
                                    <a href="./events">/events</a>
                                </span>
                            ),
                            img: events,
                        },
                    ]}
                    maxWidth={2}
                    style={{ padding: 0 }}
                    cycle={true}
                />
                <p>
                    TRL ={" "}
                    <a href="https://en.wikipedia.org/wiki/Technology_readiness_level">Technology Readiness Level(s)</a>
                </p>
            </div>
        </section>
        <section>
            <div className="content stack_responsive">
                <img src={what_we_do} alt="an explanation as to what Hard Stuff does as prototyping partners" />
                <div>
                    <span className="mobile-only">
                        <h2>Physical World</h2>
                        <h2>Physical Problems</h2>
                    </span>
                    <h2 className="desktop-only">Physical World, Physical Problems</h2>
                    <p>Hard Stuff works with the startups that are solving the really tough problems.</p>
                    <p>
                        We bring that perfect blend of engineering talent and climate startup experience to bring your
                        ideas to life.
                    </p>
                    <p>
                        We operate in agriculture, renewables, environmental monitoring, Internet of Things,
                        heating/cooling, clean manufacturing, and circular economy.
                    </p>
                    <ItsTimeToBuild />

                    <a href="https://calendar.app.google/LKY4VL67TaSfDuLGA">Tell us what you're building.</a>
                </div>
            </div>
        </section>
        <section>
            <div className="content" style={{ textAlign: "left" }}>
                <span className="quote">
                    <p>
                        "The prototyping work has helped us understand what we would be able to offer customers. Instead
                        of starting from scratch, we are now more confident in being able to iterate using existing
                        products, while also having a clearer understanding of where further innovation would open up
                        new product opportunities.
                    </p>
                    <p>
                        We really liked the “can do” attitude of Hard Stuff, and their no-nonsense way of structuring
                        the contract and delivery milestones, which went further that we imagined at the start of the
                        project. We feel their work represents very good value for money."
                    </p>
                </span>
                <span style={{ display: "block", textAlign: "right" }}>
                    <a href="https://www.smartshiftenergy.co.uk/">SmartShift Energy</a>
                </span>
            </div>
        </section>
        <WhatIsHardStuffDoing />
    </div>
);

export default Home;
