import React, { useState, useEffect, useRef } from "react";
import "./FlexBox.css";
import { UnmountClosed } from "react-collapse";

function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
}

const FlexBox = (props) => {
    const { elements, defaultValue, imgIsBackground, maxWidth = 3, style = {}, cycle = false } = props;
    const [highlighted, setHighlighted] = useState(null);
    const [selected, setSelected] = useState(defaultValue);
    const timeLimit = 20;
    const fractionalTime = 0.5;
    const [timeLeft, setTimeLeft] = useState(timeLimit);

    const ref = useRef();
    const isVisible = useIsVisible(ref);

    useEffect(() => {
        if (cycle) {
            if (timeLeft < 0) {
                setSelected(selected < elements.length - 1 ? selected + 1 : 0);
                setTimeLeft(timeLimit);
                return;
            }

            if (highlighted !== selected && isVisible) {
                const intervalId = setInterval(() => {
                    setTimeLeft((prevTimeLeft) => prevTimeLeft - fractionalTime);
                }, fractionalTime * 1000);

                return () => clearInterval(intervalId);
            }
        }
    }, [timeLeft, highlighted, cycle, isVisible, selected, elements.length]);

    useEffect(() => {
        setTimeLeft(timeLimit);
    }, [selected]);

    return (
        <>
            <div className="flex_box" ref={ref}>
                {elements.map((elem, idx) => (
                    <div
                        key={idx}
                        className={"flex_box_content " + (idx === selected && !imgIsBackground ? "flip-colours" : "")}
                        style={{
                            flex: selected === idx ? maxWidth : 1,
                            backgroundColor: imgIsBackground
                                ? ""
                                : idx === highlighted && idx !== selected
                                ? "#ffffff22"
                                : "",
                            backgroundImage: imgIsBackground ? `url(${elem.img})` : "",
                            backgroundSize: "cover",
                            backgroundPosition:
                                "top " + (idx === 0 ? "left" : idx === elements.length - 1 ? "right" : "center"),
                            border: imgIsBackground
                                ? "solid 2px " + (idx === highlighted ? "#fff5" : "rgb(31, 31, 38)")
                                : "",
                            ...style,
                        }}
                        onMouseEnter={() => setHighlighted(idx)}
                        onMouseLeave={() => setHighlighted(null)}
                        onClick={() => (idx === selected ? setSelected(null) : setSelected(idx))}
                    >
                        {idx === selected && (
                            <div style={{ height: 2 }}>
                                <div
                                    style={{
                                        width: `${100 - (timeLeft * 100) / timeLimit}%`,
                                        height: "100%",
                                        background: "linear-gradient(135deg, rgb(228, 124, 69), rgb(238, 77, 69))",
                                        transition: `width ${fractionalTime}s linear`,
                                    }}
                                />
                            </div>
                        )}
                        <h3>
                            {imgIsBackground ? (
                                <span style={{ background: "rgba(0,0,0,0.7)", padding: "6px" }}>{elem.title}</span>
                            ) : (
                                elem.title
                            )}
                        </h3>
                        {!imgIsBackground && elem.img && (
                            <img
                                src={elem.img}
                                alt={elem.title}
                                className={idx !== selected ? "clip_height_mobile" : ""}
                            />
                        )}
                        {elem.content && (
                            <div className="flex_box_text pillar-content">
                                <UnmountClosed isOpened={idx === selected}>
                                    {imgIsBackground ? (
                                        <p style={{ backgroundColor: "rgba(0,0,0,0.7)", padding: "6px" }}>
                                            {elem.content}
                                        </p>
                                    ) : (
                                        elem.content
                                    )}
                                </UnmountClosed>
                            </div>
                        )}
                        <span className="mobile-only">
                            <br />
                            {idx === selected ? "▲" : ". . ."}
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default FlexBox;
