import "../App.css";
import FitInFigure from "../components/FitInFigure";
import Layout from "react-masonry-list";
import MaskedImg from "../components/MaskedImg";

// featured images
import smartshift_prototype from "../assets/images/smartshift_prototype.png";
import waterqm_prototype from "../assets/images/waterqm_prototype.png";
import climate_iot from "../assets/images/climate_iot.png";
import agri_built from "../assets/images/agri_built.png";

// prototype images
import printing from "../assets/images/threeD_printing.png";
import drilling from "../assets/images/drilling.png";
import coding from "../assets/images/coding.png";
import pcb_design from "../assets/images/pcb_design.png";
import soldering from "../assets/images/pico_to_pi_soldering.png";
import waterqm_render from "../assets/images/waterqm_render.png";
import waterqm_prototype_2 from "../assets/images/waterqm_prototype (2).png";
import lab_testing from "../assets/images/lab_testing.jpg";
import engineering from "../assets/images/engineering.png";

const featured = [
    <MaskedImg
        src={smartshift_prototype}
        caption={
            <span>
                Automatic price-optimising of home energy usage to reduce bills, reduce emissions, and protect the
                national grid, with <a href="https://www.smartshiftenergy.co.uk/">SmartShift Energy</a>
            </span>
        }
    />,
    <MaskedImg
        src={waterqm_prototype}
        caption={
            <span>
                Low-cost riverway water quality monitoring to improve ecological and public health, with WaterQM. Find
                out more about our <a href="./venture-studio">venture studio</a>
            </span>
        }
    />,
    <MaskedImg
        src={climate_iot}
        caption={
            <span>
                A quick demonstrator for a client to be used to prove Internet-of-Things concepts to their investors
            </span>
        }
    />,
    <MaskedImg
        src={agri_built}
        caption={
            <span>
                Converting cow manure (a <strong>huuuge</strong> methane emitter) into valorisable fertiliser, reducing
                agriculture's environmental impact whilst saving money, with{" "}
                <a href="https://ag-e-gen.com/">Ag E Gen</a>.
            </span>
        }
    />,
];

// If item contains img elements, don't forget set img's width. In order to get a better display effect, you can also set img's `object-fit` to `contain`.

const Startups = () => {
    return (
        <>
            <h1>Our work with startups</h1>
            <div className="content">
                <h3 style={{ textAlign: "left" }}>Featured</h3>
                <Layout
                    minWidth={100}
                    colCount={2}
                    items={featured.map((item) => (
                        <div key={item.id}>{item}</div>
                    ))}
                    gap={50}
                />
                <section>
                    <div className="stack_responsive">
                        <div>
                            <h2>
                                <span className="hardstuff">Hard Stuff</span> works with hardware startups fixing hard
                                problems.
                            </h2>
                            <p>
                                We prototype, test, and build ventures alongside a generation of sustainability and
                                impactful hardware startups.
                            </p>
                            <ul style={{ textAlign: "left", marginTop: "0.25em" }}>
                                <strong>We're here for</strong>
                                <li style={{ marginBottom: "0.25em" }}>validating assumptions</li>
                                <li style={{ marginBottom: "0.25em" }}>
                                    building proof of concepts for your customers and that streamline raising investment
                                </li>
                                <li style={{ marginBottom: "0.25em" }}>
                                    slotting into your existing capabilities to cover the technical areas that you need
                                    to go <strong>BIG</strong>
                                </li>
                            </ul>
                        </div>
                        <FitInFigure height="80vh" maxWidth="80%" />
                    </div>
                </section>
                {/* <MinimumViableProduct /> */}
                <section className="stack_responsive">
                    <Layout
                        minWidth={100}
                        colCount={2}
                        items={[
                            <MaskedImg src={printing} />,
                            <MaskedImg src={drilling} />,
                            <MaskedImg src={soldering} />,
                            <MaskedImg src={lab_testing} />,
                        ].map((item) => (
                            <div key={item.id}>{item}</div>
                        ))}
                        gap={10}
                    />
                    <div>
                        <h2>How do we work with you?</h2>
                        <p>
                            We are your hardware development partner for sustainability and high-impact industries,
                            including agriculture, renewables, environmental monitoring, Internet of Things,
                            heating/cooling, clean manufacturing, and circular economy.
                        </p>
                    </div>
                </section>
                <section className="stack_responsive">
                    <div>
                        <h3 id="prototyping">Prototyping and field-testing</h3>
                        <p>
                            We operate a sprints services model, keeping sales orders quick and light so we're
                            laser-focussed on only the most critical tasks at hand.
                        </p>
                        <p>
                            A typical two to four weeks long sprint covers functional & aesthetic prototyping,
                            field-testing, and ideating.
                        </p>
                        <a href="/contact">Contact us to get started</a>
                    </div>
                    <Layout
                        minWidth={100}
                        colCount={2}
                        items={[
                            <MaskedImg src={waterqm_prototype_2} />,
                            <MaskedImg src={coding} />,
                            <MaskedImg src={waterqm_render} />,
                            <MaskedImg src={pcb_design} />,
                        ].map((item) => (
                            <div key={item.id}>{item}</div>
                        ))}
                        gap={10}
                    />
                </section>
                <section className="stack_responsive"  id="fractional-cto">
                    <img src={engineering} alt="illustrating some of our hardware capabilities" />
                    <div>
                        <h3>Fractional CTOing</h3>
                        <p>
                            Whether it's two hours a month or half a day every week, we can support your venture on the
                            technical side at the early stages of your journey.
                        </p>
                        <p>
                            We're here for the climate hardware ventures, supporting on ideation, technical challenges,
                            solution exploring, and tapping into the wider engineering community.
                        </p>
                        <p>
                            We can work as either the interim CTO or alongside your existing CTO to support the
                            technical journey.
                        </p>
                        <a href="/contact">Tell us what you need</a>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Startups;
